.tooltip_row {
  display: grid;
  margin: 0 0 0.75rem 0;
  grid-template-columns: 1fr auto;
}

.tooltip_row-label {
  @apply text-th-fgd-3;
  margin-right: 0.5rem;
}

.tooltip_row-values {
  list-style: none;
  margin: 0;
  padding: 0;
  @apply text-th-fgd-1;
}

.tooltip_row-value {
  text-align: right;
  word-break: break-all;
  @apply text-th-fgd-1;
}

.tooltip_row-values li {
  padding: 0.25rem 0 0 0;
  text-align: right;
}
