@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/f9ac909e4da1c3e0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/aae4c06610d09e19-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/7ff7e0f329e531ad-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: bold;
}

@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/7d4fb347f5fec8ea-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: semi-bold;
}@font-face {font-family: '__PPSupplyMono_Fallback_ca767d';src: local("Arial");ascent-override: 60.84%;descent-override: 15.21%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_ca767d {font-family: '__PPSupplyMono_ca767d', '__PPSupplyMono_Fallback_ca767d'
}.__variable_ca767d {--font-number: '__PPSupplyMono_ca767d', '__PPSupplyMono_Fallback_ca767d'
}

.StatsTooltip_tooltip_row__wPryJ {
  display: grid;
  margin: 0 0 0.75rem 0;
  grid-template-columns: 1fr auto;
}

.StatsTooltip_tooltip_row-label__gxae4 {
  color: var(--fgd-3);
  margin-right: 0.5rem;
}

.StatsTooltip_tooltip_row-values__HAX_9 {
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--fgd-1);
}

.StatsTooltip_tooltip_row-value__PorXQ {
  text-align: right;
  word-break: break-all;
  color: var(--fgd-1);
}

.StatsTooltip_tooltip_row-values__HAX_9 li {
  padding: 0.25rem 0 0 0;
  text-align: right;
}

